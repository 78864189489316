exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-credit-notes-js": () => import("./../../../src/pages/account/credit-notes.js" /* webpackChunkName: "component---src-pages-account-credit-notes-js" */),
  "component---src-pages-account-dashboard-js": () => import("./../../../src/pages/account/dashboard.js" /* webpackChunkName: "component---src-pages-account-dashboard-js" */),
  "component---src-pages-account-invoices-js": () => import("./../../../src/pages/account/invoices.js" /* webpackChunkName: "component---src-pages-account-invoices-js" */),
  "component---src-pages-account-orders-index-js": () => import("./../../../src/pages/account/orders/index.js" /* webpackChunkName: "component---src-pages-account-orders-index-js" */),
  "component---src-pages-account-orders-single-order-js": () => import("./../../../src/pages/account/orders/single-order.js" /* webpackChunkName: "component---src-pages-account-orders-single-order-js" */),
  "component---src-pages-account-profile-js": () => import("./../../../src/pages/account/profile.js" /* webpackChunkName: "component---src-pages-account-profile-js" */),
  "component---src-pages-account-sav-js": () => import("./../../../src/pages/account/sav.js" /* webpackChunkName: "component---src-pages-account-sav-js" */),
  "component---src-pages-all-products-js": () => import("./../../../src/pages/all-products.js" /* webpackChunkName: "component---src-pages-all-products-js" */),
  "component---src-pages-attempts-js": () => import("./../../../src/pages/attempts.js" /* webpackChunkName: "component---src-pages-attempts-js" */),
  "component---src-pages-basket-js": () => import("./../../../src/pages/basket.js" /* webpackChunkName: "component---src-pages-basket-js" */),
  "component---src-pages-choose-new-password-js": () => import("./../../../src/pages/choose-new-password.js" /* webpackChunkName: "component---src-pages-choose-new-password-js" */),
  "component---src-pages-complete-profile-js": () => import("./../../../src/pages/complete-profile.js" /* webpackChunkName: "component---src-pages-complete-profile-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-order-info-js": () => import("./../../../src/pages/order-info.js" /* webpackChunkName: "component---src-pages-order-info-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-rgpd-js": () => import("./../../../src/pages/rgpd.js" /* webpackChunkName: "component---src-pages-rgpd-js" */),
  "component---src-pages-sign-up-as-client-js": () => import("./../../../src/pages/sign-up-as-client.js" /* webpackChunkName: "component---src-pages-sign-up-as-client-js" */),
  "component---src-pages-sign-up-as-pro-js": () => import("./../../../src/pages/sign-up-as-pro.js" /* webpackChunkName: "component---src-pages-sign-up-as-pro-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

